import './polyfills';

import { initMobileNav } from './header';
import { initReviewsCaro } from './reviews';
import { contactSubmit, contactMap } from './contact.js';
import { openDets, initFacilityCaro, filterUnits, toggleMap, initFacilityMap } from './facility';

initMobileNav();

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'phone', 'email', 'message']
	});
	contactMap();
}

if(document.querySelector('#facility_page')) {
	initFacilityCaro();
	filterUnits();
	toggleMap();
	initFacilityMap();
	openDets();
}