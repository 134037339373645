import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

export const initFacilityCaro = () => {
	const options = {
		element: document.querySelector('#fac_caro'),
		images: window.facCaroImgs,
		imagesAsBackgrounds: true,
		showDots: false,
		useChevrons: true,
	};
    
	if(window.facCaroImgs.length === 1) {
		options['intervalTiming'] = false;
		options['useChevrons'] = false;
	}
	AutomatitCarousel(options);
};

export const filterUnits = () => {
	const filters = document.querySelectorAll('#fac_filters .filter');
	const units = document.querySelectorAll('#rates .unit_row');
	[].forEach.call(filters, (filter) => {
		filter.addEventListener('click', (e) => {
			let filter = e.currentTarget;
			[].forEach.call(filters, (el) => {
				el.classList.remove('active');
			});
			filter.classList.add('active');
            
			let visibleUnits = 0;
			[].forEach.call(units, (unit) => {
				unit.classList.add('hidden');
				if(unit.dataset.size === filter.dataset.size || filter.dataset.size === 'all'){
					unit.classList.remove('hidden');
					visibleUnits++;
				}
			});
            
			if(visibleUnits > 0){
				document.getElementById('no_units').style.display = 'none';
			}else{
				document.getElementById('no_units').style.display = 'block';
			}
		});
	});
};

export const toggleMap = () => {
	const mapSlide = document.getElementById('fac_map');
	const mapToggle = document.getElementById('fac_map_toggle');
	const photoToggle = document.getElementById('fac_caro_toggle');
    
	mapToggle.addEventListener('click', function(){
		mapToggle.classList.add('active');
		photoToggle.classList.remove('active');
		mapSlide.classList.add('active');
	});
	photoToggle.addEventListener('click', function(){
		mapToggle.classList.remove('active');
		photoToggle.classList.add('active');
		mapSlide.classList.remove('active');
	});
};

export const initFacilityMap = () => {
	const mapsHelper = getMapsHelper();

	mapsHelper.ready().then(() => {
		const theMap = mapsHelper.createMap({
			element: document.querySelector('#fac_map'),
			locationElementSelector: '#fac_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="" /></a>`
				};
			}
		});

		
		// eslint-disable-next-line no-undef
		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(15);
		});
	});
};

export const openDets = () => {
	document.getElementById('fac_top_toggle').addEventListener('click', (e) => {
		e.currentTarget.classList.toggle('active');
		document.getElementById('fac_top_wrapper').classList.toggle('active');
	});
};