import { AutomatitCarousel } from './automatit_carousel.m';

export const initReviewsCaro = () => {
	const intervalTiming = (document.querySelectorAll('#inc_reviews_caro .caro_slide').length === 1)?false:8000;
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#inc_reviews_caro'),
		useChevrons: false,
		showDots: false,
		intervalTiming,
		afterSlide: (p, n) => {
			// +n.dataset.slide to force integer, as all data attributes are strings
			document.querySelector('#inc_reviews_controls h4 span').innerHTML = +n.dataset.slide+1;
		}
	});
};
